<template>
  <LiefengModal
      title="修改标签"
      :fullscreen="fullscreen"
      :value="value"
      @click="valueTrue"
  >
    <template v-slot:contentarea>
      <div class="container">
        <template>
          <Form :rules="rules" :model="formLeft" id="formDow" label-position="left" :label-width="80">
            <FormItem label="标签名称">
              <Input v-model="formLeft.input1" placeholder="限10个字" maxlength="10"></Input>
            </FormItem>
          </Form>
        </template>

      </div>
    </template>
    <template v-slot:toolsbar>
      <div class="but">
        <Button>保存</Button>
      </div>
      <Button type="primary" @click="valueFalse">关闭</Button>
    </template>
  </LiefengModal>
</template>

<script lang="js">
import LiefengModal from "@/components/LiefengModal";

export default {
  name: "labeladd",
  props: {
    rules:Object,
    value: Boolean,
    fullscreen: Boolean,
    valueFalse: Function,
    valueTrue: Function,
  },
  data() {
    return {
      formLeft: {
        input1: '',
      },
    }
  },
  computed: {},
  components: {
    LiefengModal
  }
}
</script>

<style scoped lang="less">
#formDow {
}

.but {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  > Button {
    color: #10BC92
  }
}
</style>
